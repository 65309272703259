export const XS = 320;
export const SM = 568;
export const MD = 768;
export const LG = 1056;
export const XL = 1440;

export enum ALL {
  XS = 320,
  SM = 568,
  MD = 768,
  LG = 1056,
  XL = 1440,
}
