export default {
  Home: {
    path: "/",
  },
  Podcasts: {
    path: "/podcasts",
  },
  About: {
    path: "/about",
  },
  People: {
    path: "/people",
  },
  Contact: {
    path: "/contact",
  },
  Newsletter: {
    path: "/newsletter",
  },
};
