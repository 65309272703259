import React, { FC } from "react";

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  menuIsOpen?: boolean;
}

const MenuToggle: FC<Props> = ({ onClick, menuIsOpen }) => (
  <button
    className="w-3 h-3 flex flex-col items-center justify-center focus:outline-none"
    onClick={onClick}
  >
    {menuIsOpen ? (
      <svg
        className="block align-top"
        width="30px"
        height="30px"
        viewBox="0 0 30 30"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Welcome"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Mobile-Main-Menu"
            transform="translate(-277.000000, -13.000000)"
          >
            <rect
              id="Rectangle"
              fill="#000000"
              x="0"
              y="0"
              width="320"
              height="55"
            ></rect>
            <g
              id="Hamburger-Menu"
              transform="translate(292.000000, 28.000000) rotate(-315.000000) translate(-292.000000, -28.000000) translate(283.000000, 19.000000)"
              stroke="#FFFFFF"
              strokeLinecap="square"
              strokeWidth="2"
            >
              <line x1="9" y1="18" x2="9" y2="0" id="Line-2-Copy-2"></line>
              <line
                x1="1.16573418e-16"
                y1="9"
                x2="18"
                y2="9"
                id="Line-2"
              ></line>
            </g>
          </g>
        </g>
      </svg>
    ) : (
      <svg
        className="block align-top"
        width="17px"
        height="13px"
        viewBox="0 0 17 13"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Welcome"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Mobile-Homepage---ZAK"
            transform="translate(-284.000000, -21.000000)"
          >
            <rect
              id="Rectangle"
              fill="#000000"
              x="0"
              y="0"
              width="320"
              height="55"
            ></rect>
            <g
              id="Hamburger-Menu"
              transform="translate(285.000000, 22.000000)"
              stroke="#FFFFFF"
              strokeLinecap="square"
              strokeWidth="2"
            >
              <line
                x1="9.71445147e-17"
                y1="0.5"
                x2="15"
                y2="0.5"
                id="Line-2"
              ></line>
              <line
                x1="9.71445147e-17"
                y1="5.5"
                x2="15"
                y2="5.5"
                id="Line-2-Copy"
              ></line>
              <line
                x1="9.71445147e-17"
                y1="10.5"
                x2="15"
                y2="10.5"
                id="Line-2-Copy-2"
              ></line>
            </g>
          </g>
        </g>
      </svg>
    )}
  </button>
);

export default MenuToggle;
