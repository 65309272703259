export const WHITE = "#FFFFFF";
export const BLACK = "#000000";

export const GREY = "#F3F3F3";

export const DARK_CHARCOAL = "#231F20";
export const FOG = "#CDD7E1";
export const MOSS = "#B4D3B1";
export const TAUPE = "#E8E2D1";
export const GOLD = "#FEBC11";
export const BUBBLEGUM = "#FFB7DB";
