import React, { FC } from "react";

import GlobalHeader from "components/GlobalHeader";
import GlobalFooter from "components/GlobalFooter";

interface Props {
  location: Location;
}

const Layout: FC<Props> = ({ children, location }) => {
  return (
    <main className="flex flex-col min-h-screen text-base font-medium">
      <GlobalHeader location={location} />

      {children}
      {location.pathname.length > 1 && <GlobalFooter />}
    </main>
  );
};

export default Layout;
