import React, {
  FC,
  createContext,
  useState,
  useEffect,
  useCallback,
} from "react";

import { Breakpoints } from "theme";

type ContextProps = {
  deviceHasTouchEvents: boolean;
  currentBreakpoint: Breakpoints.ALL;
};

const getCurrentBreakpointFromWindowWidth = (): Breakpoints.ALL => {
  const windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
  let breakpoint: Breakpoints.ALL = Breakpoints.XS;
  if (windowWidth >= Breakpoints.SM) breakpoint = Breakpoints.SM;
  if (windowWidth >= Breakpoints.MD) breakpoint = Breakpoints.MD;
  if (windowWidth >= Breakpoints.LG) breakpoint = Breakpoints.LG;
  if (windowWidth >= Breakpoints.XL) breakpoint = Breakpoints.XL;
  return breakpoint;
};

const initialState: ContextProps = {
  deviceHasTouchEvents: false,
  currentBreakpoint: getCurrentBreakpointFromWindowWidth(),
};

export const AppContext = createContext<ContextProps>(initialState);

export const AppContextProvider: FC = ({ children }) => {
  const [state, setState] = useState(initialState);

  const setCurrentBreakpointFromWindowWidth = useCallback(() => {
    const currentBreakpoint = getCurrentBreakpointFromWindowWidth();
    setState(state => ({
      ...state,
      currentBreakpoint,
    }));
  }, []);

  const setDeviceHasTouchEvents = useCallback(() => {
    setState(state => ({
      ...state,
      deviceHasTouchEvents: true,
    }));
    window.removeEventListener("touchstart", setDeviceHasTouchEvents);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", setCurrentBreakpointFromWindowWidth);
    setCurrentBreakpointFromWindowWidth();
    return () => {
      window.removeEventListener("resize", setCurrentBreakpointFromWindowWidth);
    };
  }, [setCurrentBreakpointFromWindowWidth]);

  useEffect(() => {
    window.addEventListener("touchstart", setDeviceHasTouchEvents);
  }, [setDeviceHasTouchEvents]);

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};
