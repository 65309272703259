import React, { FC, useState } from "react";
import GatsbyLink from "gatsby-link";
import cx from "classnames";

import Routes from "constants/Routes";

import MenuToggle from "./MenuToggle";

interface Props {
  location: Location;
}

const GlobalHeader: FC<Props> = ({ location }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleLinkClick = () => {
    setMenuIsOpen(false);
  };

  return (
    <header className="fixed top-0 left-0 right-0 md:static bg-black md:bg-white font-sans flex flex-wrap md:flex-nowrap items-center md:items-end leading-none text-medium md:text-base z-50">
      <h1 className="bg-black text-white font-black flex-grow md:flex-grow-0 px-2 md:px-0">
        <GatsbyLink
          className="block flex-grow py-1.3 md:px-2.2 md:pt-3 md:pb-2.2"
          onClick={handleLinkClick}
          to="/"
        >
          <div className="w-8 h-3 md:w-18 md:h-6.6">
            <svg
              className="w-full h-full"
              width="154px"
              height="56px"
              viewBox="0 0 154 56"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-23.000000, -22.000000)" fill="#FEFEFE">
                  <g transform="translate(23.000000, 22.000000)">
                    <polygon points="5.56266593 5.91633424 5.56266593 24.8746122 15.5209551 24.8746122 15.5209551 30.2909464 5.56266593 30.2909464 5.56266593 54.6666667 0.294117647 54.6666667 0.294117647 0.5 16.1008403 0.5 16.1008403 5.91633424"></polygon>
                    <g transform="translate(20.260504, 10.500000)">
                      <path d="M11.115783,0.656111111 L11.115783,6.08722222 L8.43363175,6.08722222 C6.82423125,6.08722222 5.75038305,7.17444444 5.75038305,8.80388889 L5.75038305,44.1083333 L0.386080529,44.1083333 L0.386080529,0.656111111 L5.75038305,0.656111111 L5.75038305,1.14555556 C6.60913238,0.819444444 7.52110771,0.656111111 8.43363175,0.656111111 L11.115783,0.656111111 Z"></path>
                      <path d="M29.8902382,8.25938889 L29.8902382,25.0971667 L19.1605358,25.0971667 L19.1605358,36.5038333 C19.1605358,38.1332778 20.234384,39.2188333 21.8437845,39.2188333 C23.4520875,39.2188333 24.5259357,38.1332778 24.5259357,36.5038333 L24.5259357,30.7455 L29.8902382,30.7455 L29.8902382,36.5038333 C29.8902382,40.8482778 26.1347875,44.6505 21.8437845,44.6505 C17.5511353,44.6505 13.7962332,40.8482778 13.7962332,36.5038333 L13.7962332,8.25938889 C13.7962332,3.91494444 17.5511353,0.113277778 21.8437845,0.113277778 C26.1347875,0.113277778 29.8902382,3.91494444 29.8902382,8.25938889 L29.8902382,8.25938889 Z M24.5259357,19.6660556 L24.5259357,8.25938889 C24.5259357,6.62994444 23.4520875,5.54438889 21.8437845,5.54438889 C20.234384,5.54438889 19.1605358,6.62994444 19.1605358,8.25938889 L19.1605358,19.6660556 L24.5259357,19.6660556 Z"></path>
                      <path d="M51.3469545,8.25938889 L51.3469545,25.0971667 L40.617252,25.0971667 L40.617252,36.5038333 C40.617252,38.1332778 41.6911002,39.2188333 43.2994033,39.2188333 C44.9088038,39.2188333 45.982652,38.1332778 45.982652,36.5038333 L45.982652,30.7455 L51.3469545,30.7455 L51.3469545,36.5038333 C51.3469545,40.8482778 47.5920525,44.6505 43.2994033,44.6505 C39.0089489,44.6505 35.2529495,40.8482778 35.2529495,36.5038333 L35.2529495,8.25938889 C35.2529495,3.91494444 39.0089489,0.113277778 43.2994033,0.113277778 C47.5920525,0.113277778 51.3469545,3.91494444 51.3469545,8.25938889 L51.3469545,8.25938889 Z M45.982652,19.6660556 L45.982652,8.25938889 C45.982652,6.62994444 44.9088038,5.54438889 43.2994033,5.54438889 C41.6911002,5.54438889 40.617252,6.62994444 40.617252,8.25938889 L40.617252,19.6660556 L45.982652,19.6660556 Z"></path>
                    </g>
                    <polygon points="88.4789916 5.91633424 83.2104433 5.91633424 83.2104433 54.6666667 77.9408172 54.6666667 77.9408172 5.91633424 72.6722689 5.91633424 72.6722689 0.5 88.4789916 0.5"></polygon>
                    <path d="M94.302521,5.91633424 L99.2941176,5.91633424 L99.2941176,0.5 L94.302521,0.5 L94.302521,5.91633424 Z M94.302521,54.6666667 L99.2941176,54.6666667 L99.2941176,11.3326685 L94.302521,11.3326685 L94.302521,54.6666667 Z"></path>
                    <g transform="translate(104.285714, 10.500000)">
                      <path d="M26.8387402,8.25938889 L26.8387402,44.1082778 L21.5385993,44.1082778 L21.5385993,8.25938889 C21.5385993,6.62994444 20.4786795,5.54438889 18.8885288,5.54438889 C17.2983782,5.54438889 16.2384584,6.62994444 16.2384584,8.25938889 L16.2384584,44.1082778 L10.9383175,44.1082778 L10.9383175,8.25938889 C10.9383175,6.62994444 9.87731347,5.54438889 8.2882471,5.54438889 C6.69755424,5.54438889 5.63709234,6.62994444 5.63709234,8.25938889 L5.63709234,44.1082778 L0.336951468,44.1082778 L0.336951468,0.656055556 L5.53137145,0.656055556 C6.37930724,0.3305 7.33296393,0.113277778 8.2882471,0.113277778 C10.3018235,0.113277778 12.1570897,0.928277778 13.588388,2.2855 C15.0186019,0.928277778 16.8744103,0.113277778 18.8885288,0.113277778 C23.12875,0.113277778 26.8387402,3.91494444 26.8387402,8.25938889"></path>
                      <path d="M48.0379483,8.25938889 L48.0379483,25.0971667 L37.4365822,25.0971667 L37.4365822,36.5038333 C37.4365822,38.1332778 38.4975863,39.2188333 40.0877369,39.2188333 C41.6768033,39.2188333 42.7378074,38.1332778 42.7378074,36.5038333 L42.7378074,30.7455 L48.0379483,30.7455 L48.0379483,36.5038333 C48.0379483,40.8482778 44.3268738,44.6505 40.0877369,44.6505 C35.8464315,44.6505 32.1364413,40.8482778 32.1364413,36.5038333 L32.1364413,8.25938889 C32.1364413,3.91494444 35.8464315,0.113277778 40.0877369,0.113277778 C44.3268738,0.113277778 48.0379483,3.91494444 48.0379483,8.25938889 L48.0379483,8.25938889 Z M42.7378074,19.6660556 L42.7378074,8.25938889 C42.7378074,6.62994444 41.6768033,5.54438889 40.0877369,5.54438889 C38.4975863,5.54438889 37.4365822,6.62994444 37.4365822,8.25938889 L37.4365822,19.6660556 L42.7378074,19.6660556 Z"></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </GatsbyLink>
      </h1>
      <div className="block md:hidden px-2">
        <MenuToggle
          menuIsOpen={menuIsOpen}
          onClick={() => setMenuIsOpen(state => !state)}
        />
      </div>
      <nav
        className={cx(
          "bg-white md:bg-transparent flex flex-col md:flex-row w-full md:w-auto flex-grow items-baseline py-2 px-2 md:pt-0 md:px-1.8 md:pb-0 text-base",
          { "hidden md:flex": !menuIsOpen }
        )}
      >
        <NavLink
          to={Routes.Podcasts.path}
          onClick={handleLinkClick}
          isActive={location.pathname.startsWith(Routes.Podcasts.path)}
        >
          Podcasts
        </NavLink>
        <NavLink
          to={Routes.About.path}
          onClick={handleLinkClick}
          isActive={location.pathname.startsWith(Routes.About.path)}
        >
          About
        </NavLink>
        <NavLink
          to={Routes.People.path}
          onClick={handleLinkClick}
          isActive={location.pathname.startsWith(Routes.People.path)}
        >
          People
        </NavLink>
        <NavLink
          to={Routes.Contact.path}
          onClick={handleLinkClick}
          isActive={location.pathname.startsWith(Routes.Contact.path)}
        >
          Contact
        </NavLink>
        <NavLink
          to={Routes.Newsletter.path}
          onClick={handleLinkClick}
          isActive={location.pathname.startsWith(Routes.Newsletter.path)}
        >
          Newsletter
        </NavLink>
      </nav>
    </header>
  );
};

interface NavLinkProps {
  to: string;
  isActive?: boolean;
  onClick?: React.MouseEventHandler;
}

const NavLink: FC<NavLinkProps> = ({ to, children, isActive, onClick }) => {
  return (
    <GatsbyLink
      className={cx("block flex-grow pb-2 md:px-1.6 md:pb-1.8 md:text-center", {
        "font-bold": isActive,
        "font-normal": !isActive,
      })}
      onClick={onClick}
      to={to}
    >
      {children}
    </GatsbyLink>
  );
};

export default GlobalHeader;
