const React = require("react");
const Layout = require("./src/layouts/index").default;
const { AppContextProvider } = require("./src/state/context");

require("./src/css/fonts.css");
require("./src/css/style.css");

exports.wrapRootElement = ({ element }) => {
  return <AppContextProvider>{element}</AppContextProvider>;
};

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
